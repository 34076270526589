const { createSlice } = require('@reduxjs/toolkit');

// Initial State
const initialState = {
  loginModel: false,
  newPasswordModel: false,
  registerModel: false,
  verifyOtpModel: false,
  forgetModel: false,
  forgetOtpModel: false,
  resetPassModal: false,
  verifyMail: null,
};

const authStateSlice = createSlice({
  name: 'authState',
  initialState,
  reducers: {
    updateNewPasswordModalState(state, action) {
      return {
        ...state,
        newPasswordModel: action.payload,
        loginModel: false,
        registerModel: false,
        verifyOtpModel: false,
        forgetModel: false,
        forgetOtpModel: false,
        resetPassModal: false,
      };
    },
    updateLoginModalState(state, action) {
      return {
        ...state,
        loginModel: action.payload,
        registerModel: false,
        newPasswordModel: false,
        verifyOtpModel: false,
        forgetModel: false,
        forgetOtpModel: false,
        resetPassModal: false,
      };
    },
    updateForgotModalState(state, action) {
      return {
        ...state,
        loginModel: false,
        loginModenewPasswordModel: false,
        registerModel: false,
        verifyOtpModel: false,
        forgetModel: action.payload,
        forgetOtpModel: false,
        resetPassModal: false,
      };
    },
    updateForgotOTPModalState(state, action) {
      return {
        ...state,
        loginModel: false,
        newPasswordModel: false,
        registerModel: false,
        verifyOtpModel: false,
        forgetModel: false,
        resetPassModal: false,
        forgetOtpModel: action.payload,
      };
    },
    updateRegisterModalState(state, action) {
      return {
        ...state,
        loginModel: false,
        newPasswordModel: false,
        registerModel: action.payload,
        verifyOtpModel: false,
        forgetModel: false,
        forgetOtpModel: false,
        resetPassModal: false,
      };
    },
    updateVerifyOtpModalState(state, action) {
      return {
        ...state,
        loginModel: false,
        newPasswordModel: false,
        registerModel: false,
        verifyOtpModel: action.payload,
        forgetModel: false,
        forgetOtpModel: false,
        resetPassModal: false,
      };
    },
    updateResetPassModalState(state, action) {
      return {
        ...state,
        loginModel: false,
        newPasswordModel: false,
        registerModel: false,
        verifyOtpModel: false,
        forgetModel: false,
        forgetOtpModel: false,
        resetPassModal: action.payload,
      };
    },
    updateVerifyMailState(state, action) {
      return {
        ...state,
        verifyMail: action.payload,
      };
    },
  },
});

export const {
  updateLoginModalState,
  updateRegisterModalState,
  updateVerifyOtpModalState,
  updateForgotOTPModalState,
  updateForgotModalState,
  updateVerifyMailState,
  updateResetPassModalState,
  updateNewPasswordModalState,
} = authStateSlice.actions;

export default authStateSlice.reducer;
