import API from 'redux/api';
import { ApiError } from 'utilities/constants/messages';
import { notifyError } from 'utilities/messages/toastMessage';

export const getBanners = async (params) => {
  try {
    const bannerData = API.get('/api/v1/banner/list', {
      params,
    }).then((res) => {
      if (res.data && res.data.code === 200) {
        return res.data.data;
      }
      return res;
    });
    return bannerData;
  } catch (error) {
    notifyError({ message: ApiError });
    return false;
  }
};

export const getContentData = async (params) => {
  try {
    const res = await API.get('/api/v1/aboutUs/list', {
      params,
    });
    if (res.data && res.data.code === 200) {
      return res.data.data;
    }
    return res;
  } catch (error) {
    notifyError({ message: ApiError });
    return false;
  }
};
