// ** Redux Imports
import { combineReducers } from 'redux';

import aboutStateReducer from './AboutState';
import adminStateReducer from './AdminState';
import appStateReducer from './AppState';
import authStateReducer from './AuthState';
import paginationReducer from './Pagination';
import userStateReducer from './UserState';

// ** Reducers Imports

const rootReducer = combineReducers({
  appState: appStateReducer,
  adminState: adminStateReducer,
  authState: authStateReducer,
  appUserState: userStateReducer,
  aboutState: aboutStateReducer,
  paginationData: paginationReducer,
});

export default rootReducer;
