/* eslint-disable no-param-reassign */
const { createSlice } = require('@reduxjs/toolkit');

// Initial State
const initialState = {
  currentPage: 0,
};

const PaginationStateSlice = createSlice({
  name: 'appState',
  initialState,
  reducers: {
    updatePageState(state, action) {
      state.currentPage = action.payload;
    },
    updatePaginationStateValues(state, action) {
      const { key, value } = action.payload;
      state[key] = { ...state[key], ...value };
    },
  },
});

export const { updatePageState, updatePaginationStateValues } =
  PaginationStateSlice.actions;

export default PaginationStateSlice.reducer;
