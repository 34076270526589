// Yup Validations messages
export const REQUIRED_ERROR = (name) => `${name} is required!`;
export const ONE_UPPER_CASE = () => `At least one Uppercase is required.`;
export const ONE_LOWER_CASE = () => `At least one Lowercase is required.`;
export const ONE_DIGIT_CASE = () => `At least one digit is required.`;
export const ONE_SPECIAL_CHAR = () =>
  `At least one Special Character is required.`;
export const ALLOW_CHARACTERS = () =>
  `Only alphabets are allowed for this field`;
export const REQUIRED_TYPE = (name, type) => `${name} must be ${type}.`;
export const REQ_SELECTED = () => `At least one option must be selected.`;
export const MIN_NUMBER = (name, min) => `${name} can't be less than ${min}`;
export const MAX_NUMBER = (name, max) => `${name} can't be more than ${max}`;
export const MAX_LENGTH = (name, type) =>
  `${name} must be at most ${type} characters long.`;
export const MIN_LENGTH = (name, type) =>
  `${name} must be at least ${type} characters long.`;
export const MIN_CHECKED = (name, type) =>
  `At least ${type} option must be checked for ${name} .`;
export const MIN_SELECTED = (name, type) =>
  `At least ${type} ${name} must be selected .`;
export const MAX_SELECTED = (name, type) =>
  `At most ${type} ${name} should be selected .`;
export const SELECTED_MIN = (name, type) =>
  `At least ${type} option must be selected for ${name} .`;
export const MIN_RATE = (type) => `A min rate of ${type} should be there.`;

export const ApiError = 'Internal server error! Try again later.';
export const UnAuthorizeError = 'Unauthorized! Please login again.';
