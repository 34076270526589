export const BASE_URL_DEVELOPMENT = 'http://localhost:8080/';
export const BASE_URL_PRODUCTION = 'https://admin.askastudent.net/';
export const LOCAL_MIXPANEL_TOKEN = '017b37b41f9dd4d906cdccbfce016390';
export const PROD_MIXPANEL_TOKEN = 'db6320d0460467c406f4ed56b0594bd0';
export const GOOGLE_REVIEW_KEYS = 'AIzaSyC8qnK9z2ES5SDnDUE93HXziMabZtWcFQw';

export const SLICE_STATUES = Object.freeze({
  IDLE: 'idel',
  Error: 'error',
  LOADING: 'loading',
});

export const LISTING_COUNT = {
  MINI: 5,
  SMALL: 10,
  MEDIUM: 15,
  LARGE: 20,
};

export const PRIVACY_POLICY =
  'https://ik.imagekit.io/AskAStudent/website_private_docs/PRIVACY_POLICY_2022.04.05_nFQJivCcW.pdf?ik-sdk-version=javascript-1.4.3&updatedAt=1651946294183';

export const GOOGLE_MAP_REVIEWS =
  'https://www.google.com/search?q=askastudent&rlz=1C1ONGR_enCA1046CA1046&oq=askastudent&gs_lcrp=EgZjaHJvbWUqDAgAECMYJxiABBiKBTIMCAAQIxgnGIAEGIoFMgYIARBFGDwyBggCEEUYQTITCAMQLhivARjHARiRAhiABBiKBTIGCAQQRRg8MgYIBRBFGDwyBggGEEUYQTIGCAcQRRg8qAIAsAIB&sourceid=chrome&ie=UTF-8#lrd=0x882b358a762c7745:0x9fb0a0b3ef4e2265,1,,,,';

export const TERM_OF_USE =
  'https://ik.imagekit.io/AskAStudent/website_private_docs/TERMS___CONDITIONS_2022.04.05_VfJ2OyxQt.pdf?ik-sdk-version=javascript-1.4.3&updatedAt=1651946297661';

export const CALENDLY_LINK =
  'https://calendly.com/askastudent/introductory-calls-with-current-student-advisors';
export const PROGRAM_FINDER =
  'https://www.applyboard.com/studyabroad?profile=%7B%22dsj_lead_source%22%3A%22Ask_A_Student%22%7D';

export const timeZoneName = {
  'Etc/GMT+12': 'International Date Line West',
  'Pacific/Midway': 'Midway Island, Samoa',
  'Pacific/Honolulu': 'Hawaii',
  'America/Juneau': 'Alaska',
  'America/Dawson': 'Pacific Time (US and Canada); Tijuana',
  'America/Boise': 'Mountain Time (US and Canada)',
  'America/Chihuahua': 'Chihuahua, La Paz, Mazatlan',
  'America/Phoenix': 'Arizona',
  'America/Chicago': 'Central Time (US and Canada)',
  'America/Regina': 'Saskatchewan',
  'America/Mexico_City': 'Guadalajara, Mexico City, Monterrey',
  'America/Belize': 'Central America',
  'America/Detroit': 'Eastern Time (US and Canada)',
  'America/Indiana/Indianapolis': 'Indiana (East)',
  'America/Bogota': 'Bogota, Lima, Quito',
  'America/Glace_Bay': 'Atlantic Time (Canada)',
  'America/Caracas': 'Caracas, La Paz',
  'America/Santiago': 'Santiago',
  'America/St_Johns': 'Newfoundland and Labrador',
  'America/Sao_Paulo': 'Brasilia',
  'America/Argentina/Buenos_Aires': 'Buenos Aires, Georgetown',
  'America/Godthab': 'Greenland',
  'Etc/GMT+2': 'Mid-Atlantic',
  'Atlantic/Azores': 'Azores',
  'Atlantic/Cape_Verde': 'Cape Verde Islands',
  GMT: 'Dublin, Edinburgh, Lisbon, London',
  'Africa/Casablanca': 'Casablanca, Monrovia',
  'Atlantic/Canary': 'Canary Islands',
  'Europe/Belgrade': 'Belgrade, Bratislava, Budapest, Ljubljana, Prague',
  'Europe/Sarajevo': 'Sarajevo, Skopje, Warsaw, Zagreb',
  'Europe/Brussels': 'Brussels, Copenhagen, Madrid, Paris',
  'Europe/Amsterdam': 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
  'Africa/Algiers': 'West Central Africa',
  'Europe/Bucharest': 'Bucharest',
  'Africa/Cairo': 'Cairo',
  'Europe/Helsinki': 'Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius',
  'Europe/Athens': 'Athens, Istanbul, Minsk',
  'Asia/Jerusalem': 'Jerusalem',
  'Africa/Harare': 'Harare, Pretoria',
  'Europe/Moscow': 'Moscow, St. Petersburg, Volgograd',
  'Asia/Kuwait': 'Kuwait, Riyadh',
  'Africa/Nairobi': 'Nairobi',
  'Asia/Baghdad': 'Baghdad',
  'Asia/Tehran': 'Tehran',
  'Asia/Dubai': 'Abu Dhabi, Muscat',
  'Asia/Baku': 'Baku, Tbilisi, Yerevan',
  'Asia/Kabul': 'Kabul',
  'Asia/Yekaterinburg': 'Ekaterinburg',
  'Asia/Karachi': 'Islamabad, Karachi, Tashkent',
  'Asia/Calcutta': 'Chennai, Kolkata, Mumbai, New Delhi',
  'Asia/Kathmandu': 'Kathmandu',
  'Asia/Dhaka': 'Astana, Dhaka',
  'Asia/Colombo': 'Sri Jayawardenepura',
  'Asia/Almaty': 'Almaty, Novosibirsk',
  'Asia/Rangoon': 'Yangon Rangoon',
  'Asia/Bangkok': 'Bangkok, Hanoi, Jakarta',
  'Asia/Krasnoyarsk': 'Krasnoyarsk',
  'Asia/Shanghai': 'Beijing, Chongqing, Hong Kong SAR, Urumqi',
  'Asia/Kuala_Lumpur': 'Kuala Lumpur, Singapore',
  'Asia/Taipei': 'Taipei',
  'Australia/Perth': 'Perth',
  'Asia/Irkutsk': 'Irkutsk, Ulaanbaatar',
  'Asia/Seoul': 'Seoul',
  'Asia/Tokyo': 'Osaka, Sapporo, Tokyo',
  'Asia/Yakutsk': 'Yakutsk',
  'Australia/Darwin': 'Darwin',
  'Australia/Adelaide': 'Adelaide',
  'Australia/Sydney': 'Canberra, Melbourne, Sydney',
  'Australia/Brisbane': 'Brisbane',
  'Australia/Hobart': 'Hobart',
  'Asia/Vladivostok': 'Vladivostok',
  'Pacific/Guam': 'Guam, Port Moresby',
  'Asia/Magadan': 'Magadan, Solomon Islands, New Caledonia',
  'Pacific/Fiji': 'Fiji Islands, Kamchatka, Marshall Islands',
  'Pacific/Auckland': 'Auckland, Wellington',
  'Pacific/Tongatapu': "Nuku'alofa",
};

export const radioOption = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const serviceProvideOption = [
  { label: 'Paid', value: 'paid' },
  { label: 'Free', value: 'free' },
];

export const serviceObj = {
  voice: {
    Title: 'Voice call',
    Description: `Voice call for quick response`,
    Rates: 0,
    shortRate: 0,
    longRate: 0,
  },
  video: {
    Title: 'Video call',
    Description: `Video call for quick and visualize response`,
    Rates: 0,
    shortRate: 0,
    longRate: 0,
  },
};

export const renderProfileType = {
  current: 'Student Advisor',
  future: 'Future Student',
  general: 'New User',
  partialcurrent: 'Current Student',
};

export const AASForum = {
  PER_QUESTION_COINS: 50,
  PER_COIN_REDEEM: 0.01,
  MIN_REDEEM: 2500,
};
