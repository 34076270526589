import { getContentData } from 'utilities/api-actions/landing-page';
import { SLICE_STATUES } from 'utilities/constants/api';
import { notifyError } from 'utilities/messages/toastMessage';

/* eslint-disable no-param-reassign */
const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

// Initial State
const initialState = {
  appContent: null,
  contentStatus: SLICE_STATUES.IDLE,
};

// Thunks

export const fetchAboutUsData = createAsyncThunk('aboutUs/fetch', async () => {
  try {
    const contentData = await getContentData({
      asc: 'key',
      status: 'active',
      fields: 'key, value, value2',
    });
    return contentData;
  } catch (error) {
    notifyError(error);
    return null;
  }
});

const AboutStateSlice = createSlice({
  name: 'aboutState',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAboutUsData.pending, (state) => {
        state.contentStatus = SLICE_STATUES.LOADING;
      })
      .addCase(fetchAboutUsData.fulfilled, (state, action) => {
        state.appContent = action.payload;
        state.contentStatus = SLICE_STATUES.IDLE;
      })
      .addCase(fetchAboutUsData.rejected, (state) => {
        state.status = SLICE_STATUES.Error;
      });
  },
});

export default AboutStateSlice.reducer;
