/* eslint-disable no-param-reassign */
const { createSlice } = require('@reduxjs/toolkit');

// Initial State
const initialState = {
  drawerState: false,
};

const AdminStateSlice = createSlice({
  name: 'adminState',
  initialState,
  reducers: {
    updateDrawerState(state, action) {
      state.drawerState = action.payload;
    },
  },
});

export const { updateDrawerState } = AdminStateSlice.actions;

export const drawerStateValue = (state) => state.adminState;

export default AdminStateSlice.reducer;
