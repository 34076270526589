/* eslint-disable no-param-reassign */
const { createSlice } = require('@reduxjs/toolkit');

// Initial State
const initialState = {
  appLanguage: null,
  appState: null,
  accountTab: null,
  communityTab: null,
  faqTab: null,
};

const AppStateSlice = createSlice({
  name: 'appState',
  initialState,
  reducers: {
    updateAppLanguage(state, action) {
      state.appLanguage = action.payload;
    },
    updateAppState(state, action) {
      state.appState = action.payload.value;
    },
    updateAccountTabState(state, action) {
      state.accountTab = action.payload.value;
    },
    updateCommunityTabState(state, action) {
      state.communityTab = action.payload.value;
    },
    updateFAQTabState(state, action) {
      state.faqTab = action.payload.value;
    },
  },
});

export const {
  updateAppLanguage,
  updateAppState,
  updateAccountTabState,
  updateCommunityTabState,
  updateFAQTabState,
} = AppStateSlice.actions;

export const communityTabValue = (state) => state.appState;

export default AppStateSlice.reducer;
