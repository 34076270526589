import { message, notification } from 'antd';

import { ApiError } from 'utilities/constants/messages';

export const notifySuccess = (content) =>
  message.success(content?.message ? content?.message : ApiError);
export const notifyError = (content) => {
  message.error(content?.message ? content?.message : ApiError);
};

export const errorNotification = (content, title = 'Error') =>
  notification.info({
    message: title,
    description: content,
  });
export const successNotification = (content, title = null) =>
  notification.success({
    message: title,
    description: content,
  });
